<template>
<h3 align="center">RESTRICTED</h3>
</template>

<script>
export default {
    created: function(){
        console.log('Created Restricted')
        let self = this
        setTimeout(function(){
            self.$router.push({name:'Home'})
        },1500)
    }
}
</script>

<style>

</style>